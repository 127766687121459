<template>
  <el-dialog append-to-body title="身份列表" ref="dialog" width="500px" :visible.sync="show">
    <div class="list" v-loading="role.loading">
      <div class="item" v-for="item in role.data" :key="item.id" @click="getRoleRouter(item)">
        <div class="user icon el-icon-user-solid"></div>
        <div class="info">
          <div class="name">{{item.org.name}}</div>
          <div class="text">{{item.role.name}}</div>
        </div>
        <div class="arrow icon el-icon-arrow-right"></div>
      </div>
    </div>
    <el-pagination
      background
      layout="total,->,prev,pager,next,jumper,->,sizes"
      :total="role.total"
      :page-count="role.pages"
      :page-size="role.query.size"
      :current-page="role.query.page"
      @size-change="handleChangePageSize"
      @current-change="handleChangeCurrent"
    ></el-pagination>
  </el-dialog>
</template>

<script>
import Login from '@/api/login';
export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      show: false,
      role: {
        loading: false,
        data: [],
        pages: 1,
        total: 0,
        query: {
          page: 1,
          size: 30,
        },
      },
    };
  },
  methods: {
    toggle(show) {
      this.show = show;
    },
    getRole() {
      this.role.loading = true;
      Login.identityList(this.role.query)
        .then((res) => {
          this.role.data = res.items;
        })
        .finally(() => {
          this.role.loading = false;
        });
    },
    // 获取路由 并且检查是否已经完善资料
    async getRoleRouter(data) {
      if (this.$store.getters.accountid === data.id) {
        // this.$message.closeAll();
        // this.$message.info('身份无变化');
      } else {
        console.log(data);
        this.$store.commit('setAccountid', data.id);
        this.$store.commit('setAccount', data);
      }
      this.$myset('account', data);
      let res = await Login.CheckPerfectInfo();
      let { status } = res;
      if (status) {
        //如果已经完善的话，就去输入验证码登录，否则就去完善资料
        this.$emit('switchComponent', 'validate');
      } else {
        this.$emit('switchComponent', 'perfectInfo');
      }
    },
    handleChangePageSize(data) {
      this.role.query.page = 1;
      this.role.query.size = data;
      this.getRole();
    },
    handleChangeCurrent(data) {
      this.role.query.page = data;
      this.getRole();
    },
  },
  mounted() {},
  async created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.list {
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    align-items: center;
    border-top: 1px solid $border1;
    padding: 0 10px;
    .user {
      color: $blue1;
      margin-right: 10px;
      padding: 4px;
      text-align: center;
    }
    .info {
      flex: 1;
    }
    .arrow {
      color: $blue1;
    }
    .icon {
      transition: all 0.2s;
    }
  }
  .item:hover {
    cursor: pointer;
    background-color: $hoverBgc1;
    .user {
      border-radius: 50%;
      background-color: $blue1;
      color: white;
    }
    .arrow {
      transform: translateX(10px);
    }
  }
  .item:nth-last-of-type(1) {
    border-bottom: 1px solid $border1;
    margin-bottom: 10px;
  }
}
</style>
